
import { Component, Vue } from 'vue-property-decorator'

@Component({})

export default class TableListVue extends Vue {
  private headers: any[] = [
    {
      sortable: false,
      text: 'Name',
      value: 'name',
    },
    {
      sortable: false,
      text: 'Country',
      value: 'country',
    },
    {
      sortable: false,
      text: 'City',
      value: 'city',
    },
    {
      sortable: false,
      text: 'Salary',
      value: 'salary',
      align: 'right',
    },
  ]
  private items: any[] = [
    {
      name: 'Dakota Rice',
      country: 'Niger',
      city: 'Oud-Tunrhout',
      salary: '$35,738',
    },
    {
      name: 'Minerva Hooper',
      country: 'Curaçao',
      city: 'Sinaai-Waas',
      salary: '$23,738',
    }, {
      name: 'Sage Rodriguez',
      country: 'Netherlands',
      city: 'Overland Park',
      salary: '$56,142',
    }, {
      name: 'Philip Chanley',
      country: 'Korea, South',
      city: 'Gloucester',
      salary: '$38,735',
    }, {
      name: 'Doris Greene',
      country: 'Malawi',
      city: 'Feldkirchen in Kārnten',
      salary: '$63,542',
    }, {
      name: 'Mason Porter',
      country: 'Chile',
      city: 'Gloucester',
      salary: '$78,615',
    },
  ]
}
